/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import { library } from "@fortawesome/fontawesome-svg-core"

import {
  faBars,
  faChevronDown,
  faChevronUp,
  faPaperPlane,
  faCircleXmark,
  faChevronRight,
  faEnvelope,
  faMobileAlt,
  faAnglesRight,
  faMapLocationDot,
} from "@fortawesome/free-solid-svg-icons"
import {
  faFacebook,
  faLinkedin,
  faTwitter,
  faWhatsapp,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"
library.add(
  faChevronDown,
  faChevronUp,
  faBars,
  faPaperPlane,
  faCircleXmark,
  faChevronRight,
  faEnvelope,
  faMobileAlt,
  faAnglesRight,
  faMapLocationDot,
  faWhatsapp,
  faFacebook,
  faLinkedin,
  faTwitter,
  faInstagram
)
