exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-crm-application-development-js": () => import("./../../../src/pages/crm-application-development.js" /* webpackChunkName: "component---src-pages-crm-application-development-js" */),
  "component---src-pages-digital-marketing-js": () => import("./../../../src/pages/digital-marketing.js" /* webpackChunkName: "component---src-pages-digital-marketing-js" */),
  "component---src-pages-erp-application-development-js": () => import("./../../../src/pages/erp-application-development.js" /* webpackChunkName: "component---src-pages-erp-application-development-js" */),
  "component---src-pages-hosting-js": () => import("./../../../src/pages/hosting.js" /* webpackChunkName: "component---src-pages-hosting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-laravel-application-development-js": () => import("./../../../src/pages/laravel-application-development.js" /* webpackChunkName: "component---src-pages-laravel-application-development-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-php-application-development-js": () => import("./../../../src/pages/php-application-development.js" /* webpackChunkName: "component---src-pages-php-application-development-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-react-js-application-development-js": () => import("./../../../src/pages/react-js-application-development.js" /* webpackChunkName: "component---src-pages-react-js-application-development-js" */),
  "component---src-pages-react-native-application-development-js": () => import("./../../../src/pages/react-native-application-development.js" /* webpackChunkName: "component---src-pages-react-native-application-development-js" */),
  "component---src-pages-search-engine-optimization-js": () => import("./../../../src/pages/search-engine-optimization.js" /* webpackChunkName: "component---src-pages-search-engine-optimization-js" */),
  "component---src-pages-software-services-js": () => import("./../../../src/pages/software-services.js" /* webpackChunkName: "component---src-pages-software-services-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-vue-js-application-development-js": () => import("./../../../src/pages/vue-js-application-development.js" /* webpackChunkName: "component---src-pages-vue-js-application-development-js" */),
  "component---src-pages-web-development-js": () => import("./../../../src/pages/web-development.js" /* webpackChunkName: "component---src-pages-web-development-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

